<template>
  <div>
    <v-card-text class="mb-0 pb-1 mt-0 pt-0">
      {{ t("use_link_to_get_commision", { value: 50 }) }}
    </v-card-text>

    <v-card-text class="mb-0 pb-0">
      <ca-text-field :value="premiumSaleLink" :label="t('premium_sale_link')" />
    </v-card-text>

    <v-card-title class="mt-0 pt-0 text-muted pb-0 mb-0">{{ t("premium_sale_banners") }}</v-card-title>
    <v-card-title class="mt-0 pt-0">
      <div class="caption" v-html="t('download_psd', { link: pdfFilePath() })"></div>
    </v-card-title>

    <v-card-text class="mb-3 pb-0">
      <v-expansion-panels>
        <v-expansion-panel v-for="(banner, index) in bannerItems" :key="index">
          <v-expansion-panel-header> {{ banner.size[0] }}x{{ banner.size[1] }} </v-expansion-panel-header>

          <v-expansion-panel-content>
            <div
              :style="{
                border: '1px solid rgb(153, 153, 153)',
                boxSizing: 'content-box',
                width: banner.size[0] + 'px',
                height: banner.size[1] + 'px'
              }"
            >
              <v-img :max-width="banner.size[0]" :max-height="banner.size[1]" :src="bannerImg(banner)"></v-img>
            </div>
            <div class="mt-5">
              <div style="max-width: 728px;">
                <v-textarea
                  :label="t('banner_code')"
                  :value="bannerCode(banner)"
                  style="font-size: 13px"
                  hide-details
                  rows="2"
                  dense
                  outlined
                  class="direction-ltr"
                />
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </div>
</template>

<script>
import CaTextField from "../../components/form/fields/TextFieldDenseAndFilled";
import { mapGetters } from "vuex";

export default {
  components: { CaTextField },
  props: {
    promoSite: Object
  },

  data() {
    return {
      promoDomain: null,
      bannerItems: []
    };
  },

  computed: {
    ...mapGetters("app", ["appAlias"]),
    apptype() {
      return this.$route.params.app;
    },
    lang() {
      return this.$store.state.auth.userData.lang;
    },
    shortDomain() {
      return this.$store.state.auth.userData.short_domains[this.apptype];
    },
    premiumSaleLink() {
      return `${this.shortDomain}/turbo?ps=${this.promoSite.id}`;
    },
  },

  mounted() {
    this.promoDomain = this.$store.state.auth.userData.promo_domain;
    this.banners();
  },

  methods: {
    banners() {
      this.axios.get("/sites-referrals/banners/search", {
        params: {
          app: this.apptype,
          lang: this.lang,
        }
      }).then(response => {
        this.bannerItems = response.data.data
      });
    },

    bannerImg(banner) {
      return `${this.shortDomain}${banner.bannerFile}`;
    },

    bannerCode(banner) {
      return banner.code.replace('%short_domain%', this.shortDomain)
    },

    pdfFilePath() {
      const name = this.appAlias(this.apptype);

      return `${this.promoDomain}/files/promo/${name}.zip`;
    },

    t(key, params) {
      return this.$t("pages.sites." + key, params);
    }
  }
};
</script>
