<template>
  <v-row>
    <v-col :cols="12">
      <page-header-with-breadcrumbs :breadcrumbs="breadcrumbs" />
    </v-col>

    <v-col :cols="12">
      <v-card>
        <v-card-title class="justify-space-between">
          {{ t("sites_referrals") }}

          <v-btn icon @click="refreshData">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>{{ t("sites_referrals_subheader") }}</v-card-subtitle>

        <v-data-table
          :loading="loadingData"
          :headers="dataTable.headers"
          :items="items"
          :sort-by.sync="sort.by"
          :sort-desc.sync="sort.desc"
          :server-items-length="totalItems"
          :items-per-page="itemsPerPage"
          :footer-props="dataTable.footerProps"
          @update:sort-desc="onUpdateSortDesc"
          @update:items-per-page="onUpdateItemsPerPage"
          @update:page="onPageUpdate"
        >
          <template #item.nurl="{item}">
            <span :class="{ 'font-weight-bold': item.promo }">
              {{ item.promo ? t("promo_link_and_banners") : item.nurl }}
            </span>
          </template>

          <template #item.today_earnings="{item}">
            {{ item.today_earnings > 0 ? t("earnings", { value: item.today_earnings }) : 0 }}
          </template>

          <template #item.weekly_earnings="{item}">
            {{ item.weekly_earnings > 0 ? t("earnings", { value: item.weekly_earnings }) : 0 }}
          </template>

          <template #item.total_earnings="{item}">
            {{ item.total_earnings > 0 ? t("earnings", { value: item.total_earnings }) : 0 }}
          </template>

          <template #item.state="{item}">
            <div class="text-center">
              <v-chip dark label small :color="statusColors[item.state]">{{ t("status." + item.state) }}</v-chip>
            </div>
          </template>

          <template #item.action="{item}">
            <div class="text-center">
              <span @click="deleteSite(item.id)">
                <ca-delete-action-icon v-if="!item.promo" />
              </span>

              <span v-if="item.state === 'created'" class="ml-1" @click="openActivateSiteDialog(item.id)">
                <ca-action-icon icon="refresh-circle" color="info" :hint="t('activate')" />
              </span>
            </div>
          </template>
        </v-data-table>

        <v-card-text style="margin-top: -50px;">
          <v-dialog v-model="newSiteDialog" width="600" persistent>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" color="primary" small light v-on="on" @click="onAddNewSite">
                <v-icon>mdi-plus</v-icon>
                {{ t("add_site") }}
              </v-btn>
            </template>

            <v-card>
              <v-stepper v-model="newSiteData.step" vertical>
                <v-stepper-step :complete="newSiteData.step > 1" step="1">
                  {{ t("enter_site_url") }}
                </v-stepper-step>
                <v-stepper-content step="1">
                  <v-text-field
                    v-model="newSiteData.url"
                    :error-messages="siteUrlErrorMessage ? [siteUrlErrorMessage] : []"
                    class="mt-0 pt-0"
                    style="max-width: 400px;"
                    :placeholder="t('site_url_placeholder')"
                  />

                  <v-btn color="primary" @click="addSite">
                    {{ $t("common.continue") }}
                  </v-btn>
                  <v-btn text @click="newSiteDialog = false">
                    {{ $t("common.cancel") }}
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="newSiteData.step > 2" step="2">
                  {{ t("verify_ownership_of_site") }}
                </v-stepper-step>
                <v-stepper-content step="2">
                  <div class="subtitle-2">{{ t("option_1") }}</div>
                  <div class="text-black mb-5">
                    <i18n path="pages.sites.option_1_text">
                      <template #file>
                        <a>turbobit.txt</a>
                      </template>

                      <template #url>
                        <a>https://example.com/turbobit.txt</a>
                      </template>

                      <template #br><br /></template>
                    </i18n>
                  </div>

                  <div class="subtitle-2">{{ t("option_2") }}</div>
                  <div class="text-black mb-5">
                    {{ t("option_2_text") }}
                    <br /><code style="font-size: 14px; margin-top: 15px;"
                      >&lt;META name='turbobit' content='{{ userLogin }}' /&gt;</code
                    >
                  </div>

                  <div v-if="activatingSiteFailed" class="mb-2 text-danger text-small">
                    File turbobit.txt is missing or lack your User Name.<br />
                    Meta tags on page lack your User Name.
                  </div>

                  <v-btn color="primary" :loading="activatingSite" @click="activateSite">
                    {{ $t("common.confirm") }}
                  </v-btn>
                  <v-btn text @click="newSiteDialog = false">
                    {{ $t("common.skip") }}
                  </v-btn>
                </v-stepper-content>

                <v-stepper-step :complete="newSiteData.step > 3" step="3">
                  {{ t("site_configuration") }}
                </v-stepper-step>
                <v-stepper-content step="3">
                  <div class="subtitle-2">{{ t("site_configuration_line_1") }}</div>
                  <div class="font-italic my-2" v-html="t('site_configuration_line_2', step3Data.line2)"></div>
                  <div class="font-italic mb-2" v-html="t('site_configuration_line_3')"></div>
                  <div class="mb-5" v-html="t('site_configuration_line_4')"></div>

                  <v-btn color="primary" @click="newSiteDialog = false">
                    {{ $t("common.done") }}
                  </v-btn>
                </v-stepper-content>
              </v-stepper>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col :cols="12">
      <v-card flat class="pb-1">
        <v-card-title class="mb-0 pb-0">{{ t("premium_sale_promo_materials") }}</v-card-title>

        <promo-materials v-if="hasPromoSite" :promo-site="promoSite" />

        <v-card-text v-else>
          <v-btn class="mt-2" color="success" :loading="creatingPromoSite" @click="createPromoSite">{{
            t("activate")
          }}</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PageHeaderWithBreadcrumbs from "../../components/PageHeaderWithBreadcrumbs";
import CaDeleteActionIcon from "../../components/actions-icons/DeleteActionIcon";
import CaActionIcon from "../../components/actions-icons/ActionIcon";
import PromoMaterials from "./PromoMaterials";

export default {
  components: { PromoMaterials, CaActionIcon, CaDeleteActionIcon, PageHeaderWithBreadcrumbs },
  metaInfo() {
    return {
      title: this.$t("titles.sites")
    };
  },
  data() {
    return {
      loadingData: false,
      activatingSite: false,
      activatingSiteFailed: false,
      creatingPromoSite: false,
      items: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      sort: {
        by: [],
        desc: []
      },
      newSiteDialog: false,
      newSiteData: {
        id: null,
        url: "",
        step: 1
      },
      siteUrlErrorMessage: ""
    };
  },
  computed: {
    hasPromoSite() {
      return this.items.filter(x => x.promo).length === 1;
    },
    promoSite() {
      const promoSites = this.items.filter(x => x.promo);

      return promoSites.length === 1 ? promoSites[0] : null;
    },
    userLogin() {
      return this.$store.state.auth.userData.email;
    },
    apptype() {
      return this.$route.params.app;
    },
    dataTable() {
      return {
        headers: [
          {
            text: this.t("data_table.fields.site"),
            value: "nurl",
            sortable: true
          },
          {
            text: this.t("data_table.fields.visits"),
            value: "hit_count",
            sortable: true
          },
          {
            text: this.t("data_table.fields.sales"),
            value: "premium_count",
            sortable: true
          },
          {
            text: this.t("data_table.fields.percent_from_sale"),
            value: "percent",
            sortable: true
          },
          {
            text: this.t("data_table.fields.today_earnings"),
            value: "today_earnings",
            sortable: true
          },
          {
            text: this.t("data_table.fields.weekly_earnings"),
            value: "weekly_earnings",
            sortable: true
          },
          {
            text: this.t("data_table.fields.total_earnings"),
            value: "total_earnings",
            sortable: true
          },
          {
            text: this.t("data_table.fields.status"),
            value: "state",
            sortable: true
          },
          {
            text: this.t("data_table.fields.action"),
            value: "action",
            sortable: false
          }
        ],
        footerProps: {
          itemsPerPageOptions: [5, 10, 25, 100]
        }
      };
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("app.sections.sites"),
          disabled: true
        }
      ];
    },
    statusColors() {
      return {
        active: "success",
        created: "",
        banned: "danger",
        deleted: "danger"
      };
    },
    step3Data() {
      return {
        line2: {
          link: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Referrer-Policy#unsafe-url",
          code: '<code style="font-size: 14px;">&lt;meta name="referrer" content="unsafe-url"></code>'
        }
      };
    }
  },
  watch: {
    $route() {
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.loadingData) {
        return;
      }

      this.loadingData = true;

      this.axios
        .get("/sites-referrals", {
          params: {
            app: this.apptype,
            page: this.currentPage,
            per_page: this.itemsPerPage,
            sort_key: this.sort.by.length > 0 ? this.sort.by[0] : null,
            sort_dir: this.sort.desc.length > 0 ? (this.sort.desc[0] ? "desc" : "asc") : null
          }
        })
        .then(response => {
          this.items = response.data.data;
          this.itemsPerPage = response.data.meta.per_page;
          this.totalItems = response.data.meta.total;

          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;

          this.$store.dispatch("app/showDialog", {
            type: "error",
            title: "Error",
            message: "Failed getting data"
          });
        });
    },
    addSite() {
      if (!this.newSiteData.url) {
        this.siteUrlErrorMessage = this.t("enter_valid_url");
        return;
      }

      this.axios
        .post("/sites-referrals/create", {
          app: this.apptype,
          url: this.newSiteData.url
        })
        .then(response => {
          this.loadData();
          this.newSiteData.step = 2;
          this.newSiteData.id = response.data.id;
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            this.siteUrlErrorMessage = error.response.data.errors.url[0];
          } else {
            this.$store.dispatch("app/showDialog", {
              type: "error",
              title: "Error",
              message: "Adding site failed"
            });
          }
        });
    },
    onAddNewSite() {
      this.newSiteData.step = 1;
      this.siteUrlErrorMessage = null;
    },
    openActivateSiteDialog(id) {
      this.newSiteData.id = id;
      this.newSiteData.step = 2;
      this.newSiteDialog = true;
      this.activatingSiteFailed = false;
    },
    activateSite() {
      this.activatingSite = true;
      this.activatingSiteFailed = false;

      this.axios
        .post("/sites-referrals/" + this.newSiteData.id + "/activate")
        .then(response => {
          this.activatingSite = false;

          if (response.data.success) {
            this.newSiteData.step = 3;
            this.loadData();
          } else {
            this.activatingSiteFailed = true;
          }
        })
        .catch(() => {
          this.activatingSite = false;
        });
    },
    deleteSite(id) {
      this.$store.dispatch("app/showConfirmDialog", {
        title: null,
        message: this.t("delete_site"),
        onCancel: () => {},
        onConfirm: () => {
          this.axios.delete("/sites-referrals/" + id).then(() => {
            this.loadData();
          });
        }
      });
    },
    createPromoSite() {
      this.creatingPromoSite = true;

      this.axios.post("/sites-referrals/promo/create?app=" + this.apptype).then(() => {
        this.creatingPromoSite = true;

        this.loadData();
      });
    },
    onUpdateItemsPerPage(itemsPerPage) {
      if (this.loadingData) {
        return;
      }

      this.itemsPerPage = itemsPerPage;
      this.loadData();
    },
    onPageUpdate(page) {
      if (this.loadingData) {
        return;
      }
      this.currentPage = page;

      // если мы переключаем количество записей на страницу находясь не на первой странице
      // то срабатывает сначала метод onPageUpdate с переключением на первую страницу, а потом onUpdateItemsPerPage
      // если мы при onPageUpdate будем загружать данные, то loadingData параметр выставится в true
      // и onUpdateItemsPerPage не срабатает и количество записей не изменяется
      // поэтому используем такое условие
      if (page > 1) {
        this.loadData();
      }
    },
    onUpdateSortDesc() {
      if (this.loadingData) {
        return;
      }

      this.loadData();
    },
    refreshData() {
      this.loadData();
    },
    t(key, params) {
      return this.$t("pages.sites." + key, params);
    }
  }
};
</script>
